import { EventEmitter, Injectable, OnDestroy } from '@angular/core'
import {
    BehaviorSubject,
    combineLatest,
    combineLatestAll,
    map,
    Observable,
    Subject,
    Subscriber,
    Subscription,
    takeUntil,
} from 'rxjs'
import {
    Profile,
    ProfileCreateState,
    ProfileDto,
    ProfilesState,
    ProfileUpdateDto,
    ProfileUpdateState,
} from '../models/profiles.model'
import { Store } from '@ngrx/store'
import { selectProfileCreateState, selectProfilesState } from '../store/selectors'
import { profileCreateActions, profileListActions, profileUpdateActions } from '../store/actions'
import { catchError } from 'rxjs/operators'
import {
    selectError,
    selectIsLoading,
    selectProfile,
    selectUpdateProfileError,
    selectUpdateProfileIsLoading,
    selectUpdateProfileSuccess,
} from '../store/reducers'

@Injectable({ providedIn: 'root' })
export class ProfilesTableService implements OnDestroy {
    isTblLoading = false
    dataChange: BehaviorSubject<Profile[]> = new BehaviorSubject<Profile[]>([])
    // Temporarily stores data from dialogs
    dialogData!: Profile

    data$: Observable<any>
    updateData$: Observable<any>

    destroy$ = new Subject<void>()

    constructor(
        private store: Store<{ profiles: ProfilesState }>,
        private createStore: Store<{
            profileCreate: ProfileCreateState
        }>,
        private updateStore: Store<{
            profileUpdate: ProfileUpdateState
        }>,
    ) {
        this.data$ = combineLatest({
            isLoading: this.createStore.select(selectIsLoading),
            error: this.createStore.select(selectError),
            profile: this.createStore.select(selectProfile),
        })

        this.updateData$ = combineLatest({
            isLoading: this.updateStore.select(selectUpdateProfileIsLoading),
            error: this.updateStore.select(selectUpdateProfileError),
            success: this.updateStore.select(selectUpdateProfileSuccess),
        })
    }
    get data(): Profile[] {
        return this.dataChange.value
    }
    getDialogData() {
        return this.dialogData
    }
    /** CRUD METHODS */
    getAllProfiles(): void {
        this.store.dispatch(profileListActions.requestProfiles())
        this.store
            .select(selectProfilesState)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (state) => {
                    // this.isTblLoading = state.isLoading
                    this.dataChange.next(state.profiles)
                },
            })
    }
    addProfile(profile: ProfileDto): void {
        this.store.dispatch(profileCreateActions.requestCreateProfile({ dto: profile }))
    }
    updateProfile(updateProfileDta: ProfileUpdateDto): void {
        this.store.dispatch(profileUpdateActions.requestUpdateProfile({ dto: updateProfileDta }))
    }
    deleteProfiles(id: number): void {
        console.log(id)

        // this.httpClient.delete(this.API_URL + id)
        //     .subscribe({
        //       next: (data) => {
        //         console.log(id);
        //       },
        //       error: (error: HttpErrorResponse) => {
        //          // error code here
        //       },
        //     });
    }

    ngOnDestroy() {
        // this.destroy$.next()
        this.isTblLoading = false
        this.destroy$.complete()
    }
}
